var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trading-vue',{ref:"tvjs",staticClass:"trading-vue",attrs:{"data":_vm.dc,"width":this.width,"height":this.height,"titleTxt":this.titleText,"toolbar":true,"extensions":this.ext,"legend-buttons":[
                  'display',
                  'up',
                  'down',
                  'add',
                  'remove',
                  'settings' ],"chart-config":{
                  TB_B_STYLE: 'line',
                  TB_BORDER: 1,
                  TB_ICON_BRI: 1.5,
                  DEFAULT_LEN: 100,
               },"overlays":_vm.overlays}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }